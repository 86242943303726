import { UsersAnalysisMetricsEnum } from "@/includes/types/usersStatByPeriod.types";
import i18n from "@/i18n/i18n";

function getTitle(metric: UsersAnalysisMetricsEnum) {
  return i18n.t(`period_metric_${ metric.toLowerCase() }_title`).toString()
}

export const metricGroups = [
  {
    group: {
      guid: "1",
      titleKey: i18n.t('users_analysis_metric_group_messages').toString(),
      groupItems: [
        { key: UsersAnalysisMetricsEnum.ReplyCount, title: getTitle(UsersAnalysisMetricsEnum.ReplyCount) },
        { key: UsersAnalysisMetricsEnum.MessageCount, title: getTitle(UsersAnalysisMetricsEnum.MessageCount) },
      ]
    }
  },
  {
    group: {
      guid: "2",
      titleKey: i18n.t('users_analysis_metric_group_points').toString(),
      groupItems: [
        { key: UsersAnalysisMetricsEnum.ReputationTake, title: getTitle(UsersAnalysisMetricsEnum.ReputationTake) },
        { key: UsersAnalysisMetricsEnum.ReputationAdd, title: getTitle(UsersAnalysisMetricsEnum.ReputationAdd) },
        { key: UsersAnalysisMetricsEnum.ApTake, title: getTitle(UsersAnalysisMetricsEnum.ApTake) },
        { key: UsersAnalysisMetricsEnum.ApAdd, title: getTitle(UsersAnalysisMetricsEnum.ApAdd) },
        { key: UsersAnalysisMetricsEnum.XpTake, title: getTitle(UsersAnalysisMetricsEnum.XpTake) },
        { key: UsersAnalysisMetricsEnum.XpAdd, title: getTitle(UsersAnalysisMetricsEnum.XpAdd) },
      ]
    }
  },
  {
    group: {
      guid: "3",
      titleKey: i18n.t('users_analysis_metric_group_punish').toString(),
      groupItems: [
        { key: UsersAnalysisMetricsEnum.PunishTake, title: getTitle(UsersAnalysisMetricsEnum.PunishTake) },
        { key: UsersAnalysisMetricsEnum.PunishAdd, title: getTitle(UsersAnalysisMetricsEnum.PunishAdd) },
        { key: UsersAnalysisMetricsEnum.WarnTake, title: getTitle(UsersAnalysisMetricsEnum.WarnTake) },
        { key: UsersAnalysisMetricsEnum.WarnAdd, title: getTitle(UsersAnalysisMetricsEnum.WarnAdd) },
      ]
    }
  },
  {
    group: {
      guid: "4",
      titleKey: i18n.t('users_analysis_metric_group_reactions').toString(),
      groupItems: [
        { key: UsersAnalysisMetricsEnum.ReactionCount, title: getTitle(UsersAnalysisMetricsEnum.ReactionCount) },
        {
          key: UsersAnalysisMetricsEnum.PositiveReactionCount,
          title: getTitle(UsersAnalysisMetricsEnum.PositiveReactionCount)
        },
        {
          key: UsersAnalysisMetricsEnum.NegativeReactionCount,
          title: getTitle(UsersAnalysisMetricsEnum.NegativeReactionCount)
        },
        { key: UsersAnalysisMetricsEnum.NoneReactionCount, title: getTitle(UsersAnalysisMetricsEnum.NoneReactionCount) },

        {
          key: UsersAnalysisMetricsEnum.ReactionMessageCount,
          title: getTitle(UsersAnalysisMetricsEnum.ReactionMessageCount)
        },
        {
          key: UsersAnalysisMetricsEnum.ReactionMessageCountTake,
          title: getTitle(UsersAnalysisMetricsEnum.ReactionMessageCountTake)
        },

        { key: UsersAnalysisMetricsEnum.ReactionCountTake, title: getTitle(UsersAnalysisMetricsEnum.ReactionCountTake) },
        {
          key: UsersAnalysisMetricsEnum.PositiveReactionCountTake,
          title: getTitle(UsersAnalysisMetricsEnum.PositiveReactionCountTake)
        },
        {
          key: UsersAnalysisMetricsEnum.NegativeReactionCountTake,
          title: getTitle(UsersAnalysisMetricsEnum.NegativeReactionCountTake)
        },
        {
          key: UsersAnalysisMetricsEnum.NoneReactionCountTake,
          title: getTitle(UsersAnalysisMetricsEnum.NoneReactionCountTake)
        },
      ]
    }
  },
  {
    group: {
      guid: "5",
      titleKey: i18n.t('users_analysis_metric_group_toxicity').toString(),
      groupItems: [
        { key: UsersAnalysisMetricsEnum.Toxicity, title: getTitle(UsersAnalysisMetricsEnum.Toxicity) },
        { key: UsersAnalysisMetricsEnum.SevereToxicity, title: getTitle(UsersAnalysisMetricsEnum.SevereToxicity) },
        { key: UsersAnalysisMetricsEnum.Profanity, title: getTitle(UsersAnalysisMetricsEnum.Profanity) },
      ]
    }
  },
  {
    group: {
      guid: "6",
      titleKey: i18n.t('users_analysis_metric_group_other').toString(),
      groupItems: [
        { key: UsersAnalysisMetricsEnum.OtherCommand, title: getTitle(UsersAnalysisMetricsEnum.OtherCommand) },
        { key: UsersAnalysisMetricsEnum.TriggerCall, title: getTitle(UsersAnalysisMetricsEnum.TriggerCall) },
      ]
    }
  }
]
