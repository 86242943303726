





































































































import SetPeriod from '@/views/chat/statistics/components/mainStatistics/setPeriod.vue'
import UserStatGlobalCardNew from '@/components/UsersStatByPeriod/new/UserStatGlobalCardNew.vue'
import UserStatUsersDataNew from '@/components/UsersStatByPeriod/new/UserStatUsersDataNew.vue'
import TagsSelect from '@/components/UsersStatByPeriod/TagsSelect.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import {
  UsersAnalysisPeriod, UsersAnalysisMetricsEnum,
  UsersAnalysisCategory,
  UsersAnalysisUserFilter,
  UsersAnalysisUserFilterMode,
} from "@/includes/types/usersStatByPeriod.types";
import TotalUsersAnalysis from "@/components/UsersStatByPeriod/new/TotalModeUsersAnalysis";
import UsersModeUsersAnalysis from "@/components/UsersStatByPeriod/new/UsersModeUsersAnalysis";
import UsersAnalysis from "@/components/UsersStatByPeriod/new/UsersAnalysis";
import TableSearchNew from "@/components/UsersStatByPeriod/new/TableSearchNew.vue";
import { metricGroups } from "@/components/UsersStatByPeriod/new/metricGroups";
import { warningNotification } from '@/includes/NotificationService'
import { isEnumValue } from '@/includes/types/shared'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import { Item } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TableSearchNew,
    TagsSelect,
    UserStatUsersDataNew,
    UserStatGlobalCardNew,
    SetPeriod,
    EmptyData,
    NotAvailableOptionsOverlay,
    SelectInput,
    DefaultList,
  }
})
export default class UsersStatByPeriodNew extends Mixins(ModelSetter, TariffsTagsHelper) {
  @Prop({ type: Boolean, default: true, required: false }) showGlobal!:Boolean

  usersAnalysis = UsersAnalysis
  totalUsersAnalysis = TotalUsersAnalysis
  usersModeUsersAnalysis = UsersModeUsersAnalysis

  isMetricsModalOpen = false

  get metricsList() {
    return metricGroups
  }

  selectMetric(item: Item) {
    const { key } = item

    if(isEnumValue(UsersAnalysisMetricsEnum, key)) {
      if (this.usersAnalysis.selectedMetrics.includes(key)) {
        warningNotification(this.$t('users_analysis_existed_metric_warning_notification').toString())
        return
      }
      else if (this.usersAnalysis.selectedMetrics.length === 5) {
        warningNotification(this.$t('users_analysis_selected_metrics_limit_warning_notification').toString())
        return
      }

      this.usersAnalysis.addMetric(key)

      this.isMetricsModalOpen = false

      this.$nextTick(() => {
        this.updateMetrics()
      })
    }
  }

  removeMetric(metric: UsersAnalysisMetricsEnum) {
    this.usersAnalysis.removeMetric(metric)
    //
    // this.totalUsersAnalysis.removeMetric(metric)
    // this.usersModeUsersAnalysis.removeMetric(metric)

    this.updateMetrics()

  }

  get categoryOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(`users_analysis_category_all`).toString(),
        value: null
      },
      {
        label: this.$t(`users_analysis_category_enter`).toString(),
        value: UsersAnalysisCategory.Enter
      },
      {
        label: this.$t(`users_analysis_category_leave`).toString(),
        value: UsersAnalysisCategory.Leave
      },
    ]
  }

  get periodGroupOptions():Array<SelectOption> {
    return [
      {
        label: this.$t(`users_analysis_group_day`).toString(),
        value: UsersAnalysisPeriod.Day
      },
      {
        label: this.$t(`users_analysis_group_week`).toString(),
        value: UsersAnalysisPeriod.Week
      },
      {
        label: this.$t(`users_analysis_group_month`).toString(),
        value: UsersAnalysisPeriod.Month
      },
      {
        label: this.$t(`users_analysis_group_year`).toString(),
        value: UsersAnalysisPeriod.Year
      },
      {
        label: this.$t(`users_analysis_group_none`).toString(),
        value: UsersAnalysisPeriod.None
      },
    ]
  }

  searchNewPeriod() {
    this.usersAnalysis.updateTicks()

    this.totalUsersAnalysis.requestTotalStat()
    this.usersModeUsersAnalysis.requestUsersStat()
  }

  updateUsers(payload: UsersAnalysisUserFilter | null) {
    this.usersModeUsersAnalysis.usersAnalysis.setUsers(payload)

    this.totalUsersAnalysis.requestTotalStat()
    this.usersModeUsersAnalysis.requestUsersStat()
  }

  updateChart(_: SelectOption | null) {
    this.totalUsersAnalysis.requestTotalStat()
    this.usersModeUsersAnalysis.requestUsersStat()
  }

  updateMetrics() {
    this.usersModeUsersAnalysis.order = this.usersAnalysis.selectedMetrics[0]
    this.totalUsersAnalysis.requestTotalStat()

    this.usersModeUsersAnalysis.requestUsersStat()
  }

  created() {
    if (this.$store.state.dashboardExtendedUsers.length) {
      this.usersAnalysis.setUsers({
        mode: UsersAnalysisUserFilterMode.Include,
        values: this.$store.state.dashboardExtendedUsers
      })
    }

    this.totalUsersAnalysis.requestTotalStat()
    this.usersModeUsersAnalysis.requestUsersStat()
  }
}
