




























import UsersStatByPeriodNew from '@/components/UsersStatByPeriod/new/UsersStatByPeriodNew.vue'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  data() {
    return {
      SitePermissionEnum
    }
  },
  components: {
    PermissionsBanner,
    NotAvailableOptionsOverlay,
    UsersStatByPeriodNew,
    PageTitle,
    MobileLandscapeTriggerLayout
  }
})
export default class StatisticsUsersAnalysisNew extends Mixins<TariffsTagsHelper>(TariffsTagsHelper) {
  key = 0

  @Watch('$route.params.CHAT_ID')
  onChatChange() {
    this.key += 1
  }

  mounted():void {
    this.$baseTemplate.saveButton.hide()
  }
}
