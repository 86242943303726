
























































import TotalUsersAnalysis from "@/components/UsersStatByPeriod/new/TotalModeUsersAnalysis";
import { UsersAnalysisMetricsEnum } from "@/includes/types/usersStatByPeriod.types";

import { Component, Emit } from 'vue-property-decorator'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

@Component({
  components: {
    VueApexCharts
  }
})
export default class UserStatGlobalCardNew extends Vue {
  totalUsersAnalysis = TotalUsersAnalysis

  @Emit()
  removeMetric(metric: UsersAnalysisMetricsEnum) {
    return metric
  }
}
