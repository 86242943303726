var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-search-new"},[_c('div',{staticClass:"flex flex-col xs:flex-row gap-2"},[_vm._t("default"),_c('search-text-input',{staticClass:"w-full ml-auto xs:w-2/3 mb-0 mt-0 pt-0 pb-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'searchQuery',
          'titleVisible': false,
          'placeholder': _vm.$t('field_users_condition_values_title')
        }
      }},on:{"pressEnter":_vm.addSearchQuery}},[_c('a-button',{attrs:{"slot":"enterButton","icon":"plus"},nativeOn:{"click":function($event){return _vm.addSearchQuery.apply(null, arguments)}},slot:"enterButton"})],1)],2),_c('a-spin',{attrs:{"spinning":_vm.usersLoading}},[(_vm.userInfos.length)?_c('div',{staticClass:"flex justify-between mt-2 items-start gap-5"},[_c('div',{staticClass:"tags flex overflow-scroll pb-2"},[_vm._l((_vm.userInfos.slice(0, 100)),function(user){return _c('a-tag',{key:user.info.id,staticClass:"tags__item",attrs:{"closable":""},on:{"close":function($event){return _vm.closeTag(user.info.id)}}},[(user.info.login)?[_vm._v(" "+_vm._s(_vm.atSignedLogin(user.info.login))+" ")]:[_vm._v(" ID: "+_vm._s(user.info.id)+" ")]],2)}),(_vm.userInfos.slice(100).length)?_c('a-tag',{staticClass:"tags__item"},[_vm._v(" "+_vm._s(_vm.$t('and_count_more', [_vm.userInfos.slice(100).length]))+" ")]):_vm._e()],2),_c('div',{staticClass:"actions flex gap-2"},_vm._l((_vm.actionButtons),function(button){return _c('a-button',{key:button.value,class:[
            !_vm.$screen.lg ? 'ant-btn-icon-only' : '',
            _vm.usersMode === button.value ? 'active' : ''
          ],attrs:{"title":button.label,"icon":button.icon && button.icon.name},on:{"click":function($event){return _vm.changeMode(button.value)}}},[(_vm.$screen.lg)?[_vm._v(" "+_vm._s(button.label)+" ")]:_vm._e()],2)}),1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }