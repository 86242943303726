import i18n from "@/i18n/i18n";
import {
  MetricChartData,
  UsersAnalysisTotal,
  UsersAnalysisMode,
  UsersAnalysisApiRes, UsersAnalysisMetricsEnum
} from "@/includes/types/usersStatByPeriod.types";
import { errorNotification } from "@/includes/NotificationService";
import { getMetricColorNew } from "@/components/UsersStatByPeriod/logic/getMetricColor";
import convertLabels from "@/components/UsersStatByPeriod/logic/convertLabels";
import UsersModeUsersAnalysis from "@/components/UsersStatByPeriod/new/UsersModeUsersAnalysis";
import UsersAnalysis from "@/components/UsersStatByPeriod/new/UsersAnalysis";

import { FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import { snakeCase } from "lodash";
import { ApexOptions } from "apexcharts";
import { constArrayIncludes } from "@/includes/types/shared";

class TotalModeUsersAnalysis {
  usersAnalysis = UsersAnalysis
  usersModeUsersAnalysis = UsersModeUsersAnalysis

  statistics: Array<MetricChartData> | null = null

  isLoading = false

  requestTotalStat(): void {
    this.isLoading = true

    this.usersAnalysis.validateRequest()
        .then(() => {
          this.usersAnalysis.getUserStatisticsByPeriod<UsersAnalysisMode.Total>(UsersAnalysisMode.Total)
              .then(res => {
                if (res) {
                  this.generateCharts(res)
                      .then(stat => {
                        this.statistics = stat
                      })
                      .catch(errorNotification)
                      .finally(() => {
                        this.isLoading = false
                      })

                  this.usersModeUsersAnalysis.count = res.count
                } else {
                  this.isLoading = false
                }
              })
        })
        .catch(error => {
          errorNotification(error)

          this.isLoading = false
        })
  }

  columnOptions(metric: UsersAnalysisMetricsEnum): ApexOptions {
    return {
      chart: {
        type: 'bar',
        height: '100',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [ getMetricColorNew(metric) ],
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: '100%'
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: convertLabels(this.usersAnalysis.ticks.timeTicks),
      },
      yaxis: {
        show: false,
        decimalsInFloat: 2
      },
      tooltip: {
        fixed: {
          enabled: true,
        },
        marker: {
          show: false
        },
        y: {
          formatter: (val: number, opts?: any): string => {
            if (constArrayIncludes(this.usersAnalysis.TOXICITY_METRICS, metric)) {
              return val.toFixed(2) + '%'
            }

            return val.toFixed(0)
          },
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }

  getMetricData(statistics: UsersAnalysisTotal, metric: UsersAnalysisMetricsEnum, fromToTicks: FromToTicksReturnType) {
    const { mapTimeData } = fromToTicks

    const metricData = statistics[metric]
    const snakeCaseMetric = snakeCase(metric)
    const seriesName = i18n.t(`series_name_${ snakeCaseMetric }`).toString()

    return new Promise<MetricChartData>(resolve => {
      if (metricData) {
        const metricHasDetails = typeof metricData === 'object'
        const total = metricHasDetails ? metricData.total : metricData

        resolve({
          metric,
          total,
          title: i18n.t(snakeCaseMetric).toString(),
          options: this.columnOptions(metric),
          series: metricHasDetails ? [ {
            name: seriesName,
            data: Object.values(Object.assign({ ...mapTimeData }, metricData.details))
          } ] : null
        })
      }

      resolve({
        metric,
        title: i18n.t(snakeCaseMetric).toString(),
        total: '-',
        options: this.columnOptions(metric),
        series: [ {
          name: seriesName,
          data: Object.values(mapTimeData)
        } ],
      })
    })

  }

  totalField(statistics: UsersAnalysisApiRes<UsersAnalysisMode.Total>, fromToTicks: FromToTicksReturnType) {
    return this.usersAnalysis.selectedMetrics.map((metric: UsersAnalysisMetricsEnum) => this.getMetricData(statistics, metric, fromToTicks))
  }

  generateCharts(rawStat: UsersAnalysisApiRes<UsersAnalysisMode.Total>) {
    return Promise.all(this.totalField(rawStat, this.usersAnalysis.ticks))
        .then(res => res)
  }

  removeMetric(metric: UsersAnalysisMetricsEnum) {
    if (this.statistics) {
      this.statistics = this.statistics.filter(s => s.metric !== metric)
    }
  }
}

export default new TotalModeUsersAnalysis()
