var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-stat-by-period"},[_c('a-modal',{attrs:{"footer":null,"title":_vm.$t('users_analysis_add_metrics'),"width":"90%"},model:{value:(_vm.isMetricsModalOpen),callback:function ($$v) {_vm.isMetricsModalOpen=$$v},expression:"isMetricsModalOpen"}},[_c('default-list',{attrs:{"config":{
        'data': _vm.metricsList,
        'selectFn': _vm.selectMetric,
      }}})],1),(_vm.getTagsByFieldKey('statistic_users_analysis'))?_c('not-available-options-overlay',{attrs:{"tags":_vm.getTagsByFieldKey('statistic_users_analysis')}}):_c('a-card',{staticClass:"w-full",attrs:{"size":"small"}},[_c('set-period',{on:{"searchPeriod":_vm.searchNewPeriod},model:{value:(_vm.usersAnalysis.period),callback:function ($$v) {_vm.$set(_vm.usersAnalysis, "period", $$v)},expression:"usersAnalysis.period"}}),_c('div',{staticClass:"flex flex-col xs:flex-row gap-2"},[_c('select-input',{staticClass:"w-full pt-0 pb-0 mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.usersAnalysis,
            'key': 'group',
            'prefix': 'users_analysis_',
            'options': _vm.periodGroupOptions,
            'clearable': false,
            'supportButtons': false
          }
        }},on:{"input":_vm.updateChart}}),_c('select-input',{staticClass:"w-full pt-0 pb-0 mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.usersAnalysis,
            'key': 'category',
            'prefix': 'users_analysis_',
            'options': _vm.categoryOptions,
            'clearable': false,
            'supportButtons': false
          }
        }},on:{"input":_vm.updateChart}})],1)],1),_c('table-search-new',{staticClass:"mt-2",attrs:{"get-users-info":_vm.usersModeUsersAnalysis.usersAnalysis.loadUsersInfo,"users":_vm.usersModeUsersAnalysis.usersAnalysis.users},on:{"searchQuery:update":function () {},"update-users":_vm.updateUsers,"reset-search":function($event){return _vm.updateUsers(null)}}},[_c('a-button',{attrs:{"icon":"plus"},on:{"click":function($event){_vm.isMetricsModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('users_analysis_add_metrics'))+" ")])],1),(_vm.usersAnalysis.hasMetrics)?[_c('user-stat-global-card-new',{staticClass:"mt-2",on:{"remove-metric":_vm.removeMetric}}),_c('user-stat-users-data-new',{staticClass:"mt-2",on:{"update-users":_vm.updateUsers}})]:_c('empty-data',{staticClass:"mt-5",attrs:{"description":_vm.$t('empty_metrics_notification')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }