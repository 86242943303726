import {
  UsersAnalysisPeriod,
  UsersAnalysisCategory, UsersAnalysisUserFilter,
  UsersAnalysisMode,
  UsersAnalysisReq, UsersAnalysisMetricsEnum
} from "@/includes/types/usersStatByPeriod.types";
import StatisticsApi from "@/includes/Api/Statistics.api";
import { errorNotification } from "@/includes/NotificationService";
import store from "@/store/store";
import i18n from "@/i18n/i18n";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";

import { fromToTicks, FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import moment from "moment/moment";
import { DurationInputArg2 } from "moment";

const MOMENT_FORMAT = 'YYYY-MM-DD'

class UsersAnalysis {
  selectedMetrics: Array<UsersAnalysisMetricsEnum> = [ UsersAnalysisMetricsEnum.MessageCount, UsersAnalysisMetricsEnum.ReplyCount, UsersAnalysisMetricsEnum.ReputationTake ]

  TOXICITY_METRICS = [
      UsersAnalysisMetricsEnum.Toxicity,
    UsersAnalysisMetricsEnum.SevereToxicity,
    UsersAnalysisMetricsEnum.Profanity ] as const

  removeMetric(metric: UsersAnalysisMetricsEnum) {
    this.selectedMetrics = this.selectedMetrics.filter(m => m !== metric)
  }

  addMetric(metric: UsersAnalysisMetricsEnum) {
    this.selectedMetrics.push(metric)
  }

  get hasMetrics() {
    return !!this.selectedMetrics.length
  }

  group = UsersAnalysisPeriod.Week

  category: UsersAnalysisCategory | null = null

  users: UsersAnalysisUserFilter | null = null

  setUsers(payload: UsersAnalysisUserFilter | null) {
    this.users = payload
  }

  period: { from: string, to: string } = {
    from: moment().add(-3, 'month').format(MOMENT_FORMAT),
    to: moment().format(MOMENT_FORMAT),
  }

  ticks: FromToTicksReturnType = this.getFromToTicks()

  updateTicks() {
    this.ticks = this.getFromToTicks()
  }

  getFromToTicks() {
    const { from, to } = this.updateDateToStart()

    return fromToTicks(from, to, { unit: this.tickUnitByPeriod })
  }

  updateDateToStart() {
    let { from, to } = this.period

    let resFrom = ''

    if (this.group === UsersAnalysisPeriod.Week) {
      resFrom = moment(from).startOf('week').format(MOMENT_FORMAT)
    } else if (this.group === UsersAnalysisPeriod.Month) {
      resFrom = moment(from).startOf('month').format(MOMENT_FORMAT)
    } else if (this.group === UsersAnalysisPeriod.Year) {
      resFrom = moment(from).startOf('year').format(MOMENT_FORMAT)
    } else {
      resFrom = moment(from).startOf('day').format(MOMENT_FORMAT)
    }

    return { from: resFrom, to }
  }

  validateRequest():Promise<boolean | string> {
    return new Promise<boolean>((resolve, reject) => {
      if (moment(this.period.from).diff(moment(this.period.to), 'd') < -370) {
        reject(i18n.t('period_limit'))
      } else if (this.selectedMetrics.length <= 0) {
        reject(i18n.t('empty_metrics_notification'))
      } else {
        resolve(true)
      }
    })
  }

  get tickUnitByPeriod(): DurationInputArg2 {
    switch (this.group) {
      case UsersAnalysisPeriod.Day:
        return 'day'
      case UsersAnalysisPeriod.Week:
      default:
        return 'week'
      case UsersAnalysisPeriod.Month:
        return 'month'
      case UsersAnalysisPeriod.Year:
        return 'year'
    }
  }

  get isGrouped() {
    return this.group !== UsersAnalysisPeriod.None
  }

  getUserStatisticsByPeriod<T>(mode: UsersAnalysisMode, fields?: Partial<UsersAnalysisReq>) {
    return StatisticsApi.getUserStatisticsByPeriod<T>({
      chat_id: store.getters.chatId,
      from: this.period.from,
      to: this.period.to,
      metrics: this.selectedMetrics,
      mode,
      period: this.isGrouped ? this.group : undefined,
      category: this.category ? this.category : undefined,
      users: this.users && this.users.values.length ? this.users : undefined,
      ...fields,
    })
        .then(res => res.data)
        .catch(errorNotification)
  }

  loadUsersInfo(ids?: Array<string>) {
    return new Promise((resolve) => {
      const uIds = store.state.dashboardExtendedUsers.length ? store.state.dashboardExtendedUsers : (ids ?? [])

      if (uIds.length) {
        const getUserInfoService = new GetUserInfoServiceImpl(store.getters.chatId)

        getUserInfoService.getUsersInfos(uIds)
            .then(res =>  resolve(res ?? []))
            .then(() => {
              store.commit('clear_users')
            })
      } else {
        resolve([])
      }
    })
  }
}

export default new UsersAnalysis()
